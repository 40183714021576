export default {
  state: {
    version: '199c',
    appInfoVisible: true,
    darkMode: false,
    latestSeenVersion: 0,
    userHasSeenCurrentVersion: false
  },
  getters: {
    version: state => state.version,
    darkMode: state => state.darkMode,
    appInfoVisible: state => state.appInfoVisible,
    userHasSeenCurrentVersion: state => state.userHasSeenCurrentVersion,
    seenUkraineBanner: state => state.seenUkraineBanner
  },
  mutations: {
    setDurablePrefs(state, {appInfoVisible, darkMode, latestSeenVersion, seenUkraineBanner}) {
      state.appInfoVisible = appInfoVisible;
      state.darkMode = darkMode;
      state.latestSeenVersion = latestSeenVersion;
      state.seenUkraineBanner = seenUkraineBanner;
      savePrefsToLocalStorage(state);
    },
    setLatestSeenVersion(state, latestSeenVersion) {
      state.latestSeenVersion = latestSeenVersion;
      savePrefsToLocalStorage(state);
    },
    setUserHasSeenCurrentVersion(state, userHasSeenCurrentVersion) {
      state.userHasSeenCurrentVersion = userHasSeenCurrentVersion;
    },
    toggleAppInfoVisibility(state) {
      state.appInfoVisible = !state.appInfoVisible;
      savePrefsToLocalStorage(state);
    },
    toggleDarkMode(state) {
      state.darkMode = !state.darkMode;
      savePrefsToLocalStorage(state);
    },
    setSeenUkraineBanner(state) {
      state.seenUkraineBanner = true;
      savePrefsToLocalStorage(state);
    }
  },
  actions: {
    loadPreferences({state, commit}) {
      try {
        const prefs = JSON.parse(localStorage.getItem('Preferences'));
        commit('setDurablePrefs', {
          appInfoVisible: prefs.appInfoVisible,
          darkMode: prefs.darkMode,
          latestSeenVersion: prefs.latestSeenVersion,
          seenUkraineBanner: prefs.seenUkraineBanner
        });
      }
      catch {
      };
      const appVersion = state.version;
      commit('setUserHasSeenCurrentVersion', appVersion==state.latestSeenVersion);
      commit('setLatestSeenVersion', appVersion);
    }
  }
}

function savePrefsToLocalStorage(state) {
  const prefs = {
    appInfoVisible: state.appInfoVisible,
    darkMode: state.darkMode,
    latestSeenVersion: state.latestSeenVersion,
    seenUkraineBanner: state.seenUkraineBanner
  };
  localStorage.setItem('Preferences', JSON.stringify(prefs));
}
